import React, { Component } from "react";
import loadable from "@loadable/component";
import { BrowserView, MobileView } from "react-device-detect";

class App extends Component {
  render() {
    const WebComponent = loadable(() => import("./WebLoad/WebLoad"));
    const MobileComponent = loadable(() => import("./MobileLoad/MobileLoad"));

    return (
      <div>
        <BrowserView>
          <WebComponent />
        </BrowserView>
        <MobileView>
          <MobileComponent />
        </MobileView>
      </div>
    );
  }
}

export default App;
